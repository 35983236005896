// Just used for local development of the component(s) which are exported from src/index.tsx
import { PrimeReactProvider } from "primereact/api";
import "./theme.css";
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import { trpc } from "@arena-active/trpc-client";
import { useState } from "react";
import { Exercises } from "./Exercises";
import TimeAgo from "javascript-time-ago";
import styles from "./App.module.css";
import en from "javascript-time-ago/locale/en";
import { SignedIn, SignedOut, SignIn, useAuth } from "@clerk/clerk-react";
import { API_URL } from "./config";

TimeAgo.addDefaultLocale(en);

function App() {
  const { getToken } = useAuth();

  console.log("App.tsx", import.meta.env.VITE_API_HOST);
  const [queryClient] = useState(() => new QueryClient());

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: API_URL,
          async headers() {
            const token = await getToken();
            return {
              Authorization: `Bearer ${token}`,
            };
          },
        }),
      ],
    }),
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <PrimeReactProvider>
          <SignedOut>
            <div className={styles.centeredContainer}>
              <SignIn />
            </div>
          </SignedOut>
          <SignedIn>
            <Exercises />
          </SignedIn>
        </PrimeReactProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export default App;
