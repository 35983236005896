import styles from "./FeedbackAnalysis.module.css";
import ratingStyles from "./RatingStyles.module.css";
import { firstBit } from "./utils/firstBit";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Response } from "./Exercises";

type Props = {
  selectedResponse: Response;
};

export const FeedbackAnalysis = ({ selectedResponse }: Props) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <FontAwesomeIcon icon={faBolt} className={styles.icon} />
      <div className={styles.middle}>
        <div className={styles.heading}>Feedback Analysis</div>
        <div>Suggestions based on user response.</div>
      </div>
      <div className={styles.rating}>
        <div>Rating</div>
        <div
          className={
            ratingStyles[firstBit(selectedResponse?.response?.evaluationRating)]
          }
        >
          {selectedResponse?.response?.evaluationRating}
        </div>
      </div>
    </div>
    <div className={styles.feedback}>
      {selectedResponse?.response?.evaluationRationale}
    </div>
  </div>
);
