import { Player, VideoPlayer } from "@arena-active/video-player";
import React, { useEffect, useRef, useState } from "react";
import { Response } from "./Exercises";
import styles from "./LessonVideo.module.css";
interface LessonVideoProps {
  selectedResponse: Response;
}

export const LessonVideo: React.FC<LessonVideoProps> = ({
  selectedResponse,
}) => {
  const playerRef = useRef<Player | null>(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  useEffect(() => {
    if (isPlayerReady && selectedResponse) {
      playerRef.current?.src({
        src: selectedResponse?.activity?.activeSession?.origin?.lesson
          ?.videoUrl,
        // type: selectedResponse?.activity?.prompt?.type,
      });
    }
  }, [selectedResponse, isPlayerReady]);

  return (
    <>
      {selectedResponse?.activity?.activeSession?.origin?.lesson?.videoUrl && (
        <VideoPlayer
          options={{
            autoplay: false,
            controls: true,
            fluid: true,
            loop: false,
            preload: "auto",
          }}
          onReady={(player: Player) => {
            playerRef.current = player;
            setIsPlayerReady(true);
          }}
        />
      )}
      {selectedResponse?.activity?.activeSession?.origin?.lesson
        ?.lessonPageUrl && (
        <iframe
          className={styles.iframe}
          src={
            selectedResponse.activity.activeSession.origin.lesson.lessonPageUrl
          }
        >
          Lesson Page here
        </iframe>
      )}
    </>
  );
};
