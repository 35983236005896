import styles from "./InstructorFeedback.module.css";
import { Response } from "./Exercises";
import { ResponsePlayer } from "@arena-active/client-lib";

type Props = {
  selectedResponse: Response;
};
export const InstructorFeedback = ({ selectedResponse }: Props) => (
  <div className={styles.container}>
    <h2>Instructor's Feedback</h2>
    <div
      className={styles.text}
      dangerouslySetInnerHTML={{
        __html: selectedResponse?.response?.instructorFeedback,
      }}
    />
    {selectedResponse?.response?.instructorFeedbackStorageKey && (
      <ResponsePlayer
        videoObjectKey={
          selectedResponse?.response?.instructorFeedbackStorageKey
        }
      />
    )}
  </div>
);
