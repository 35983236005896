import { useEffect, useState } from "react";
import styles from "./Respond.module.css";
import { Button } from "primereact/button";
import { Editor } from "primereact/editor";
import { Response, SubmitPayload } from "./Exercises";

type Props = {
  response: Response;
  onSubmit: (payload: SubmitPayload) => void;
  onCancel: () => void;
};

export const RespondWithText = ({ response, onSubmit, onCancel }: Props) => {
  const [originalText, setOriginalText] = useState("");
  const [text, setText] = useState("");
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (response) {
      setText(
        response?.response?.instructorFeedback ||
          response?.response?.evaluationFeedback,
      );
      setOriginalText(
        response?.response?.instructorFeedback ||
          response?.response?.evaluationFeedback,
      );
    }
  }, [response]);

  return (
    <div className={styles.write}>
      <h2>Feedback Script/Message</h2>
      <div className={styles.heading}>
        Use this text for recording a video review response, or to send a
        message to the student.
      </div>
      <hr />
      {!editing && (
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
      )}
      {editing && (
        <Editor
          value={text}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onTextChange={(e) => setText(e.htmlValue as any)}
          className={styles.editor}
        />
      )}
      <div className={styles.buttons}>
        {!editing && (
          <Button
            label="Submit"
            type="submit"
            onClick={() => {
              onSubmit({ text });
            }}
          />
        )}
        {!editing && <Button label="Edit" onClick={() => setEditing(true)} />}
        {editing && <Button label="Save" onClick={() => setEditing(false)} />}
        <Button
          label="Cancel"
          severity="secondary"
          onClick={() => {
            if (editing) {
              setEditing(false);
              setText(originalText);
            } else {
              setText(originalText);
              onCancel();
            }
          }}
        />
      </div>
    </div>
  );
};
