import { useEffect, useState } from "react";
import styles from "./Respond.module.css";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { RespondWithVideoWithStorage } from "./RespondWithVideo";
import { RespondWithText } from "./RespondWithText";
import { Response, SubmitPayload } from "./Exercises";

type Props = {
  response: Response;
  onSubmit: (payload: SubmitPayload) => void;
  onCancel: () => void;
};

export const Respond = ({ response, onSubmit, onCancel }: Props) => {
  const [screen, setScreen] = useState("initial");

  useEffect(() => {
    if (response) {
      if (response?.response?.instructorFeedback) {
        setScreen("submitted");
      } else {
        setScreen("initial");
      }
    }
  }, [response]);

  const handleCancel = () => {
    setScreen("initial");
    onCancel();
  };

  let body = <></>;
  if (screen === "initial") {
    body = (
      <div className={styles.initial}>
        <div>Send Feedback</div>
        <hr />
        <div className={styles.buttons}>
          {" "}
          <Button
            icon="pi pi-video"
            label="Record a video"
            onClick={() => setScreen("recordVideo")}
          />
          <Button
            icon="pi pi-user-edit"
            label="Write a response"
            onClick={() => setScreen("write")}
          />
        </div>
        <hr />
        <div>
          <Button
            label="Cancel"
            severity="secondary"
            onClick={() => {
              setScreen("initial");
              onCancel();
            }}
          />
        </div>
      </div>
    );
  }

  if (screen === "write") {
    body = (
      <RespondWithText
        response={response}
        onSubmit={(payload: SubmitPayload) => {
          onSubmit(payload);
          setScreen("submitted");
        }}
        onCancel={() => {
          handleCancel();
        }}
      />
    );
  }

  if (screen === "recordVideo") {
    body = (
      <RespondWithVideoWithStorage
        response={response}
        onSubmit={(payload) => {
          onSubmit(payload);
          setScreen("submitted");
        }}
        onCancel={onCancel}
      />
    );
  }

  if (screen === "submitted") {
    body = (
      <div className={styles.centered}>
        <div className={styles.submitted}>
          <h2>
            <FontAwesomeIcon icon={faThumbsUp} className={styles.icon} />
            Submission Successful
          </h2>
          <div>
            Your response has been successfully submitted. Thank you for your
            contribution!
          </div>
        </div>
        <Button label="Return" onClick={() => handleCancel()} />
      </div>
    );
  }

  return <div className={styles.container}>{body}</div>;
};
