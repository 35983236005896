/* eslint-disable @typescript-eslint/no-explicit-any */

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DateTime } from "luxon";
import React, { useRef, useState } from "react";
import { TabMenu } from "primereact/tabmenu";
import ratingStyles from "./RatingStyles.module.css";
import { firstBit } from "./utils/firstBit";
import styles from "./ActivityStatesTable.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFont, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";
import TimeAgo from "javascript-time-ago";
import { Checkbox } from "primereact/checkbox";
import { Nullable } from "primereact/ts-helpers";
import { Response } from "./Exercises";

interface ActivityStatesTableProps {
  activityStates: any;
  lessons: any;
  selectedResponseId: Nullable<number>;
  onTableDataChange: any;
  onSelect: (item: any) => void;
  onExerciseFilterChange: (value: any) => void;
}

export const ActivityStatesTable: React.FC<ActivityStatesTableProps> = ({
  activityStates,
  lessons,
  selectedResponseId,
  onTableDataChange,
  onSelect,
  onExerciseFilterChange,
}) => {
  const [typeFilter, setTypeFilter] = useState<boolean>(false);
  const [ratingFilter, setRatingFilter] = useState<boolean>(false);
  const nameBody = (e: any) => <div>{e?.student?.fullName}</div>;

  const dateBody = (e: any) => (
    <div>
      {new TimeAgo("en-US").format(
        DateTime.fromJSDate(e?.createdAt).toJSDate(),
        "round",
      )}
    </div>
  );

  const exerciseBody = (e: any) => (
    <div>
      Exercise #{e?.exerciseSequence} &bull;{" "}
      {e?.activity?.activeSession?.origin?.lesson?.title}
    </div>
  );

  const typeBody = (e: any) => (
    <div>
      <FontAwesomeIcon
        icon={e?.activity?.responseType === "text" ? faFont : faVideo}
        style={{ marginRight: 10 }}
      />
      {e?.activity?.responseType}
    </div>
  );

  const ratingBody = (e: any) => (
    <div
      className={ratingStyles[firstBit(e?.response?.evaluationRating)]}
      style={{ textTransform: "capitalize" }}
    >
      {e?.response?.evaluationRating}
    </div>
  );

  const dateFilterTemplate = (options: any) => (
    <>
      <Calendar
        value={options.value}
        inline={true}
        hideOnDateTimeSelect={true}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
      />
    </>
  );

  const ratingOptions = [
    "All",
    "Excellent",
    "Good",
    "Satisfactory",
    "Needs work",
  ];

  const typeOptions = ["text", "video", "audio"];
  const ratingFilterTemplate = (options: any) => (
    <>
      {options.index == 0 && (
        <div className={styles.all}>
          <Checkbox
            inputId={`ratingAll`}
            checked={options.value !== null}
            onClick={() => {
              if (!options.value) {
                options.filterCallback("some", 0);
                options.filterCallback("Excellent", 1);
                options.filterCallback("Good", 2);
                options.filterCallback("Satisfactory", 3);
                options.filterCallback("Needs work", 4);
              } else {
                options.filterCallback(null, 0);
                options.filterCallback("not_selected", 1);
                options.filterCallback("not_selected", 2);
                options.filterCallback("not_selected", 3);
                options.filterCallback("not_selected", 4);
              }
            }}
          />
          <label htmlFor={`ratingAll`} className="ml-2">
            All
          </label>
        </div>
      )}
      {options.index > 0 && options.index < 5 && (
        <>
          <Checkbox
            inputId={`rating${options.index}`}
            name="rating"
            value={ratingOptions[options.index]}
            onChange={(e) => {
              options.filterCallback(null, 0);
              options.filterCallback(
                e.checked ? ratingOptions[options.index] : "not_selected",
                options.index,
              );
            }}
            checked={options.value !== "not_selected"}
          />
          <label htmlFor={`rating${options.index}`} className="ml-2">
            {ratingOptions[options.index]}
          </label>
        </>
      )}
    </>
  );

  const typeFilterTemplate = (options: any) => (
    <div>
      <Checkbox
        inputId={`type${options.index}`}
        name="type"
        value={typeOptions[options.index]}
        onChange={(e) => {
          options.filterCallback(
            e.checked ? typeOptions[options.index] : "not_selected",
            options.index,
          );
        }}
        checked={options.value !== "not_selected"}
      />
      <label htmlFor={`type${options.index}`} className="ml-2">
        {typeOptions[options.index]}
      </label>
    </div>
  );

  const exerciseFilterTemplate = (options: any) => {
    return Object.keys(lessons).map((lesson: any, lessonIndex) => (
      <div key={lessons[lesson].id} className={styles.exerciseFilter}>
        <div className={styles.lesson}>
          <Checkbox
            inputId={`exercise#${lessonIndex}#all`}
            name="exercise"
            value={`exercise#${lessonIndex}#all`}
            onChange={(e) => {
              const filters: any = options.value || {};
              const lessonId = lessons[lesson].id;
              filters[lessonId] = filters[lessonId] || {};
              filters[lessonId]["all"] = e.checked;
              options.filterCallback(filters);
            }}
            checked={((options.value || {})[lessons[lesson]?.id] || {})["all"]}
          />
          <label htmlFor={`exercise#${lessonIndex}#all`} className="ml-2">
            {lesson}
          </label>
        </div>
        <div>
          {Object.keys(lessons[lesson].exercises).map((_, exerciseIndex) => (
            <div
              key={`${lessons[lesson].id}#${exerciseIndex}`}
              className={styles.exercise}
            >
              <Checkbox
                inputId={`exercise#${lessonIndex}#${exerciseIndex}`}
                name="exercise"
                value={`exercise#${lessonIndex}#${exerciseIndex}`}
                onChange={(e) => {
                  const filters: any = options.value || {};
                  const lessonId = lessons[lesson].id;
                  filters[lessonId] = filters[lessonId] || {};
                  filters[lessonId][exerciseIndex] = e.checked;
                  options.filterCallback(filters);
                }}
                checked={
                  ((options.value || {})[lessons[lesson]?.id] || {})[
                    exerciseIndex
                  ]
                }
              />
              <label
                htmlFor={`exercise#${lessonIndex}#${exerciseIndex}`}
                className="ml-4"
              >
                Exercise #{exerciseIndex + 1}
              </label>
            </div>
          ))}
        </div>
      </div>
    ));
  };

  const mapDates = (data: any) =>
    (data || []).map((row: any) => ({
      ...row,
      createdAt: new Date(row.createdAt),
    }));

  const [filterIndex, setFilterIndex] = useState(0);

  const tableRef: any = useRef();
  const items = [{ label: "To Respond" }, { label: "Has Response" }];

  const hasFeedback = (response: Response) =>
    (response?.response?.instructorFeedback || "").length > 0 ||
    (response?.response?.instructorFeedbackStorageKey || "").length > 0;

  const filterByState = (activityStates: any) =>
    (activityStates || []).filter((response: any) => {
      if (filterIndex === 0 && !hasFeedback(response)) {
        return true;
      }

      if (filterIndex === 1 && hasFeedback(response)) {
        return true;
      }

      return false;
    });

  const [filters] = useState({
    "student.fullName": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    createdAt: { value: null, matchMode: FilterMatchMode.DATE_IS },
    "response.evaluationRating": {
      operator: FilterOperator.OR,
      constraints: [
        { value: "some", matchMode: FilterMatchMode.NOT_EQUALS },

        ...ratingOptions.slice(1).map((option) => ({
          value: option,
          matchMode: FilterMatchMode.EQUALS,
        })),
      ],
    },
    "activity.responseType": {
      operator: FilterOperator.OR,
      constraints: typeOptions.map((option) => ({
        value: option,
        matchMode: FilterMatchMode.EQUALS,
      })),
    },
    activityId: {
      value: null,
      matchMode: FilterMatchMode.NOT_EQUALS,
    },
  });

  return (
    <div>
      <TabMenu
        model={items}
        activeIndex={filterIndex}
        onTabChange={(e) => setFilterIndex(e.index)}
      />

      <DataTable
        ref={tableRef}
        filters={filters}
        filterDisplay="menu"
        value={mapDates(filterByState(activityStates))}
        className={styles.dataTable}
        tableClassName={styles.table}
        sortField="student.fullName"
        sortOrder={1}
        rowClassName={(row: any) => {
          return row?.id == selectedResponseId ? styles.selected : styles.row;
        }}
        onRowClick={(ev) => {
          onSelect(ev.data);
          tableRef.current.filter();
        }}
        onValueChange={(val) => onTableDataChange(val)}
      >
        <Column
          header="Student"
          field="student.fullName"
          sortable
          filter
          filterPlaceholder="Search by name"
          body={nameBody}
        />
        <Column
          header="Date"
          field="createdAt"
          dataType="date"
          sortable
          filter
          showFilterMatchModes={true}
          filterElement={dateFilterTemplate}
          filterHeaderStyle={{ background: "red" }}
          body={dateBody}
        />
        <Column
          header="Exercise"
          field="activityId"
          filter
          body={exerciseBody}
          showFilterMatchModes={false}
          showFilterOperator={false}
          showFilterMenuOptions={false}
          filterElement={exerciseFilterTemplate}
          onFilterApplyClick={(event) => {
            console.log("apply exercise", event);
            onExerciseFilterChange((event.constraints as any).value);
          }}
          onFilterClear={() => {
            onExerciseFilterChange(null);
          }}
        />
        <Column
          header="Type"
          field="activity.responseType"
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showFilterMenuOptions={false}
          filterElement={typeFilterTemplate}
          filterClear={() => <></>}
          pt={{
            filterRemove: { style: { display: "none" } },
            filterConstraint: { style: { borderBottom: "none " } },
            filterMenuButton: {
              style: {
                background: typeFilter ? "#FFD7CD" : "none",
                color: "black",
              },
            },
          }}
          onFilterApplyClick={() => {
            setTypeFilter(true);
          }}
          sortable
          body={typeBody}
        />
        <Column
          header="Rating"
          field="response.evaluationRating"
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showFilterMenuOptions={false}
          sortable
          body={ratingBody}
          filterElement={ratingFilterTemplate}
          filterClear={() => <></>}
          pt={{
            filterRemove: { style: { display: "none" } },
            filterConstraint: { style: { borderBottom: "none " } },
            filterMenuButton: {
              style: {
                background: ratingFilter ? "#FFD7CD" : "none",
                color: "black",
              },
            },
          }}
          onFilterApplyClick={() => {
            setRatingFilter(true);
          }}
        />
      </DataTable>
    </div>
  );
};
