import React from "react";
import styles from "./Submission.module.css";
import { ResponsePlayer } from "@arena-active/client-lib";

interface SubmissionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedResponse: any;
}

export const Submission: React.FC<SubmissionProps> = ({ selectedResponse }) => (
  <>
    <div
      className={styles.container}
      dangerouslySetInnerHTML={{
        __html: selectedResponse?.response?.responseText,
      }}
    />
    {selectedResponse?.response?.videoStorageKey && (
      <ResponsePlayer
        videoObjectKey={selectedResponse?.response?.videoStorageKey}
      />
    )}
  </>
);
