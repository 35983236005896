import styles from "./StudentInfo.module.css";
import { Button } from "primereact/button";
import TimeAgo from "javascript-time-ago";
import { DateTime } from "luxon";

type AppProps = {
  response: any;
  showNavigation: boolean;
  showRespond: boolean;
  canRespond: boolean;
  canGoNext: boolean;
  canGoPrev: boolean;
  onRespond: Function;
  onPrev: Function;
  onNext: Function;
};

export const StudentInfo = ({
  response,
  showNavigation,
  showRespond,
  canRespond,
  canGoPrev,
  canGoNext,
  onRespond,
  onPrev,
  onNext,
}: AppProps) => (
  <div className={styles.container}>
    <div>
      <div>
        <div className={styles.name}>
          {response?.student?.fullName} &bull;{" "}
          {new TimeAgo("en-US").format(
            DateTime.fromISO(response.createdAt).toJSDate(),
          )}
        </div>
      </div>
      <div>
        Exercises &gt;{" "}
        {response?.activity?.activeSession?.origin?.lesson?.title} &gt; Exercise
        #{response?.exerciseSequence}
      </div>
    </div>

    {showNavigation && (
      <div className={styles.buttons}>
        <Button
          icon="pi pi-chevron-left"
          outlined
          severity="secondary"
          disabled={!canGoPrev}
          onClick={() => onPrev()}
        />
        <Button
          icon="pi pi-chevron-right"
          outlined
          severity="secondary"
          disabled={!canGoNext}
          onClick={() => onNext()}
        />
      </div>
    )}
    {showRespond && (
      <Button
        label="Respond"
        type="submit"
        onClick={() => onRespond()}
        disabled={!canRespond}
      />
    )}
  </div>
);
