import { Player, VideoPlayer } from "@arena-active/video-player";
import React, { useEffect, useRef, useState } from "react";
import { Response } from "./Exercises";
import styles from "./ExercisePrompt.module.css";

interface ExercisePromptProps {
  selectedResponse: Response;
}

export const ExercisePrompt: React.FC<ExercisePromptProps> = ({
  selectedResponse,
}) => {
  const playerRef = useRef<Player | null>(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  const prefix = (s: string) =>
    s?.startsWith("/") ? import.meta.env.VITE_STUDENT_UI_HOST + s : s;

  useEffect(() => {
    if (isPlayerReady && selectedResponse) {
      playerRef.current?.src({
        src: prefix(selectedResponse?.activity?.prompt?.src),
        // type: selectedResponse?.activity?.prompt?.type,
      });
    }
  }, [selectedResponse, isPlayerReady]);

  return selectedResponse?.activity?.prompt?.src ? (
    <VideoPlayer
      options={{
        autoplay: false,
        controls: true,
        fluid: true,
        loop: false,
        preload: "auto",
      }}
      onReady={(player: Player) => {
        playerRef.current = player;
        setIsPlayerReady(true);
      }}
    />
  ) : (
    <div className={styles.text}>{selectedResponse?.activity?.text}</div>
  );
};
