/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "wouter";
import { ExercisePrompt } from "./ExercisePrompt";
import { LessonVideo } from "./LessonVideo";
import { SelectButton } from "primereact/selectbutton";
import { Submission } from "./Submission";
import { trpc } from "@arena-active/trpc-client";
import { ActivityStatesTable } from "./ActivityStatesTable";
import { StudentInfo } from "./StudentInfo";
import { Respond } from "./Respond";
import styles from "./Exercises.module.css";
import { FeedbackAnalysis } from "./FeedbackAnalysis";
import { animated, useSpring } from "@react-spring/web";
import { ObjectUtils } from "primereact/utils";
import { InstructorFeedback } from "./InstructorFeedback";
import { Nullable } from "primereact/ts-helpers";

interface ExercisesProps {}

export type Response = {
  id: number;
  activity: {
    prompt: {
      src: string;
    };
    text: string;
    activeSession: {
      origin: {
        lesson: {
          videoUrl: string;
          lessonPageUrl: string;
        };
      };
    };
  };
  response: {
    id: number;
    instructorFeedback: string;
    instructorFeedbackStorageKey: string;
    evaluationRating: string;
    evaluationRationale: string;
    evaluationFeedback: string;
    videoStorageKey: string;
  };
};

export type SubmitPayload = {
  text?: string;
  videoUrl?: string;
};

export const Exercises: React.FC<ExercisesProps> = () => {
  const [fixtureStudents, setFixtureStudents] = useState(null);
  const [selectedResponseId, setSelectedResponseId] =
    useState<Nullable<number>>(null);
  const [exerciseFilter, setExerciseFilter] = useState(null);
  const [location] = useLocation();
  const [selectedTab, setSelectedTab] = useState("submission");
  const [responding, setResponding] = useState(false);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const updateResponse = trpc.activity.updateResponse.useMutation();
  const utils = trpc.useUtils();
  const remoteStudents = trpc.instructor.getInstructorData.useQuery();

  if (location.endsWith("/fixture")) {
    import("../fixtures/activityStates").then((module) => {
      setFixtureStudents(module.activityStates as any);
    });
  }

  const students = useMemo(
    () => fixtureStudents || remoteStudents?.data || [],
    [fixtureStudents, remoteStudents],
  );

  const selectedResponseIndex = (() => {
    if (!tableData) {
      return -1;
    }
    for (let i = 0; i < tableData.length; i++) {
      if (tableData[i].id === selectedResponseId) {
        return i;
      }
    }
    return -1;
  })();

  const options = [
    { name: "User Submission", value: "submission" },
    { name: "Exercise Prompt", value: "exercise" },
    { name: "Lesson Video", value: "lesson" },
  ];

  async function handleSubmitFeedback(payload: SubmitPayload) {
    if (!selectedResponse) {
      console.log("no response");
      return;
    }

    console.log("mutating", {
      id: selectedResponse?.response?.id as number,
      instructorFeedback: payload.text,
      instructorFeedbackStorageKey: payload.videoUrl,
    });
    const result = await updateResponse.mutateAsync({
      id: selectedResponse?.response?.id as number,
      instructorFeedback: payload.text,
      instructorFeedbackStorageKey: payload.videoUrl,
    });
    utils.instructor.getInstructorData.invalidate();
    console.log("update result", result);
  }

  const handleSelect = (response: Response) => {
    console.log("selecting ", response.id);
    setSelectedResponseId(response.id);
    setResponding(false);
  };

  const handleCancel = () => {
    setResponding(false);
  };

  const handlePrev = () => {
    if (selectedResponseIndex > 0) {
      setSelectedResponseId(tableData[selectedResponseIndex - 1].id);
    }
  };

  const handleNext = () => {
    if (
      selectedResponseIndex >= 0 &&
      selectedResponseIndex < tableData.length - 1
    ) {
      setSelectedResponseId(tableData[selectedResponseIndex + 1].id);
    }
  };

  const activityStates = students
    ?.map((student: any) =>
      student.activityStates.map((state: any) => ({
        ...state,
        student: { fullName: student?.fullName },
      })),
    )
    .filter((states: any) => states.length > 0)
    .flat();

  const selectedResponse: Response = activityStates?.find(
    (r: Response) => r.id === selectedResponseId,
  );

  const { leftFlex, rightFlex } = useSpring({
    leftFlex: !responding ? 1 : 0,
    rightFlex: responding ? 1 : 0,
    config: {
      duration: 200,
    },
  });

  const filterByExercise = (states: any) => {
    if (!exerciseFilter) {
      return states;
    }
    const filteredStates: any[] = [];
    states.forEach((state: any) => {
      const lessonId = state?.activity?.activeSession?.origin?.lesson?.id;
      if (
        exerciseFilter[lessonId] &&
        (exerciseFilter[lessonId]["all"] ||
          exerciseFilter[lessonId][state.exerciseSequence - 1])
      ) {
        filteredStates.push(state);
      }
    });
    return filteredStates;
  };

  const [lessons, setLessons] = useState(null);

  useEffect(() => {
    if (remoteStudents?.failureCount > 0) {
      return;
    }
    const lessonsWithExercises: any = {};
    activityStates.forEach((state) => {
      const lessonTitle =
        state?.activity?.activeSession?.origin?.lesson?.title || "";
      lessonsWithExercises[lessonTitle] = lessonsWithExercises[lessonTitle] || {
        id: state?.activity?.activeSession?.origin?.lesson?.id,
        exercises: {},
      };
      lessonsWithExercises[lessonTitle].exercises[state?.activityId] = true;
    });
    setLessons(lessonsWithExercises);
  }, [students]);

  const hasInstructorFeedback =
    !ObjectUtils.isEmpty(selectedResponse?.response?.instructorFeedback) ||
    !ObjectUtils.isEmpty(
      selectedResponse?.response?.instructorFeedbackStorageKey,
    );

  return remoteStudents.failureCount > 0 ? (
    "Could not load instructor data..."
  ) : (
    <div className={styles.container}>
      <animated.div
        className={styles.column}
        style={{
          flex: leftFlex,
        }}
      >
        <h1>Exercises</h1>
        <ActivityStatesTable
          activityStates={filterByExercise(activityStates)}
          lessons={lessons}
          selectedResponseId={selectedResponseId}
          onTableDataChange={setTableData}
          onSelect={handleSelect}
          onExerciseFilterChange={setExerciseFilter}
        />
      </animated.div>
      {selectedResponse && (
        <div>
          <div className={styles.submission}>
            <StudentInfo
              response={selectedResponse}
              showRespond={!responding}
              showNavigation={responding}
              canRespond={ObjectUtils.isEmpty(
                selectedResponse?.response?.instructorFeedback,
              )}
              canGoPrev={selectedResponseIndex > 0}
              canGoNext={
                selectedResponseIndex > -1 &&
                selectedResponseIndex < tableData?.length - 1
              }
              onRespond={() => setResponding(true)}
              onPrev={handlePrev}
              onNext={handleNext}
            />
            <SelectButton
              optionLabel="name"
              options={
                selectedResponse.activity.activeSession.origin.lesson
                  .lessonPageUrl
                  ? options.map((option) =>
                      option.value === "lesson"
                        ? { ...option, name: "Lesson Page" }
                        : option,
                    )
                  : options
              }
              value={selectedTab}
              onChange={(e) => e.value && setSelectedTab(e.value)}
            />

            {selectedTab == "submission" && (
              <Submission selectedResponse={selectedResponse} />
            )}
            {selectedTab == "exercise" && (
              <ExercisePrompt selectedResponse={selectedResponse} />
            )}
            {selectedTab == "lesson" && (
              <LessonVideo selectedResponse={selectedResponse} />
            )}
          </div>
          {hasInstructorFeedback && (
            <InstructorFeedback selectedResponse={selectedResponse} />
          )}
          <FeedbackAnalysis selectedResponse={selectedResponse} />
        </div>
      )}

      <animated.div
        style={{
          flex: rightFlex,
        }}
        className=""
      >
        <Respond
          response={selectedResponse}
          onSubmit={handleSubmitFeedback}
          onCancel={handleCancel}
        />
      </animated.div>
    </div>
  );
};
