import { useEffect, useState } from "react";
import styles from "./Respond.module.css";
import { Button } from "primereact/button";
import {
  createGUID,
  UserMediaRecorder,
  UploadBlob,
  StorageClient,
  withStorageClient,
  getExtensionForMimeType,
} from "@arena-active/client-lib";
import { Response, SubmitPayload } from "./Exercises";
import { API_URL } from "./config";

type Props = {
  response: Response;
  onSubmit: (payload: SubmitPayload) => void;
  onCancel: () => void;
  storageClient?: ReturnType<typeof StorageClient>;
};

export const RespondWithVideo = ({
  response,
  storageClient,
  onSubmit,
  onCancel,
}: Props) => {
  const [editing, setEditing] = useState(false);
  const [videoResponse, setVideoResponse] = useState<UploadBlob | null>(null);
  const [audioResponse, setAudioResponse] = useState<UploadBlob | null>(null);
  const [videoResponseObjectKey, setVideoResponseObjectkey] = useState<
    string | undefined
  >();
  const [audioResponseObjectKey, setAudioResponseObjectkey] = useState<
    string | undefined
  >();

  const handleReset = () => {
    setVideoResponse(null);
    setAudioResponse(null);
    //webcamRef.current?.reset();
  };

  const handleOnComplete = (audioBlob: UploadBlob, videoBlob: UploadBlob) => {
    setVideoResponse(videoBlob);
    setAudioResponse(audioBlob);
  };

  const handleSubmit = () => {
    const audioFileBaseName =
      "response/" + createGUID() + response.id + "-audio";
    const videoFileBaseName =
      "response/" + createGUID() + response.id + "-video";

    audioResponse &&
      storageClient
        ?.uploadFile(
          audioResponse,
          `${audioFileBaseName}.${getExtensionForMimeType(audioResponse.type)}`,
          (progress) => {
            console.log(`audio progress: ${progress}`);
          },
        )
        .then((response) => {
          response.success
            ? setAudioResponseObjectkey(
                `${audioFileBaseName}.${getExtensionForMimeType(
                  audioResponse.type,
                )}`,
              )
            : console.error(`audio upload failed: ${response.message}`);
        });

    videoResponse &&
      storageClient
        ?.uploadFile(
          videoResponse,
          `${videoFileBaseName}.${getExtensionForMimeType(videoResponse.type)}`,
          (progress) => {
            console.log(`video progress: ${progress}`);
          },
        )
        .then((response) => {
          response.success
            ? setVideoResponseObjectkey(
                `${videoFileBaseName}.${getExtensionForMimeType(
                  videoResponse.type,
                )}`,
              )
            : console.error(`video upload failed: ${response.message}`);
        });
  };

  useEffect(() => {
    console.log("Video Upload Completed, urL: ", videoResponseObjectKey);
    console.log(audioResponseObjectKey);
    if (videoResponseObjectKey) {
      onSubmit({ videoUrl: videoResponseObjectKey });
    }
  }, [videoResponseObjectKey, audioResponseObjectKey, onSubmit]);

  // const onRecordButtonClick = () => {
  //   if (videoRecordingStatus === "ready") {
  //     setVideoRecordingStatus("record");
  //     return;
  //   }
  //   setVideoRecordingStatus("ready");
  // };

  //const hasRecordedResponse: boolean = !videoResponse;

  return (
    <>
      <div className={styles.write}>
        <UserMediaRecorder
          //ref={webcamRef}
          //status={videoRecordingStatus}
          onMediaRecorded={handleOnComplete}
          //onRecordButtonClick={onRecordButtonClick}
          // hasRecordedResponse={hasRecordedResponse}
        />

        <div className={styles.buttons}>
          {/* {!videoResponse && (
            <Button
              onClick={onRecordButtonClick}
              size="small"
              icon={
                videoRecordingStatus === "ready" ? "pi pi-video" : "pi pi-stop"
              }
              label={
                videoRecordingStatus === "ready"
                  ? "Start Recording"
                  : "Stop Recording"
              }
              style={{
                backgroundColor:
                  videoRecordingStatus === "ready" ? "#EE4923" : "#111111",
              }}
            />
          )} */}

          {videoResponse && audioResponse && (
            <Button
              label="Submit"
              type="submit"
              size="small"
              onClick={() => {
                handleSubmit();
              }}
            />
          )}

          {videoResponse && (
            <Button
              size="small"
              label="Reset"
              onClick={handleReset}
              style={{ color: "#2D2D2D", backgroundColor: "#D1D1D1" }}
            />
          )}

          <Button
            label="Cancel"
            severity="secondary"
            size="small"
            onClick={() => {
              if (editing) {
                setEditing(false);
              } else {
                onCancel();
              }
            }}
          />
        </div>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: response?.response?.evaluationFeedback,
          }}
        ></div>
      </div>
    </>
  );
};

export const RespondWithVideoWithStorage = withStorageClient(
  RespondWithVideo,
  API_URL,
);
